export enum API_ROUTES {
  ANALYTICS = "/api/Analytics",
  AUTHENTICATION = "/api/authentication",
  CONVERSATION_DETAILS = "/api/ConversationDetails",
  CONVERSATION_PLAYBACK = "/api/ConversationPlayback",
  FILTERS = '/api/filters',
  PERFORMANCE_DETAILS = "/api/PerformanceDetails",
  SEARCH = 'api/Search',
  SUCCESS_INTELLIGENCE_METRICS = "/api/SuccessIntelligenceMetrics",
  USER_PROFILE = 'api/UserProfile',
};