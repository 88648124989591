import { defineNuxtPlugin } from "nuxt/app";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('tooltip', {
    beforeMount(el, binding) {
      const tooltipText = binding.value;
      if (!tooltipText) return;

      el.addEventListener('mouseenter', 
        function () {
          const tooltip = document.createElement('div');
          tooltip.className = 'directive-tooltip';
          tooltip.textContent = tooltipText;

          if (binding.arg && !isNaN(Number(binding.arg.toString()))) {
            tooltip.style.top = `${binding.arg}px`;
          }
          
          el.appendChild(tooltip);
      });

      el.addEventListener('mouseleave', 
        function () {
          const tooltip = el.querySelector('.directive-tooltip');
          if (tooltip) {
            el.removeChild(tooltip);
          }
      });
    },
  })
});