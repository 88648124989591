import { defineStore } from "pinia";

interface ISuccessIntelligenceMarkupStoreState {
  mainContentMarginTop: number;
  successIntelligenceDashboardMenuHeight: number;
  successIntelligenceKpiRowElementHeight: number;
  topbarHeight: number;
}

export const useSuccessIntelligenceMarkupStore = defineStore("successIntelligenceMarkupStore", {
  state: () => ({
    mainContentMarginTop: 20,
    successIntelligenceDashboardMenuHeight: 0,
    successIntelligenceKpiRowElementHeight: 0,
    topbarHeight: 0
  }) as ISuccessIntelligenceMarkupStoreState,
});