<template>
  <div class="main-container">
    <!-- Sidebar -->
    <aside class="aside">
      <the-sidebar-menu :menu-items="availableMenuItems"></the-sidebar-menu>
    </aside>

    <div class="main-content">
      <div class="top-bar">
        <slot name="topbar"></slot>
      </div>
      <div class="page-content-wrapper">
        <div id="page-content" class="page-content" :class="{'no-v-margin' : isPageContentWithoutVMargin}">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
  <Teleport to="body">
    <div class="notification-wrapper">
      <transition-group name="list" tag="div" class="notification-list">
        <mc-notification v-for="notification in notifications" :id="notification.id" :key="notification.id" :title="notification.title" :message="notification.message"></mc-notification>
      </transition-group>
    </div>
  </Teleport>
  <mc-unsaved-changes-dialog></mc-unsaved-changes-dialog>
</template>

<script setup lang="ts">
import { onMounted, computed, ref, nextTick } from "vue";
import { useRoute } from "vue-router";
import McNotification from "@/components/ui/McNotification.vue";
import McUnsavedChangesDialog from "@/components/ui/McUnsavedChangesDialog.vue";
import TheSidebarMenu from "@/components/shared/layout/TheSidebarMenu.vue";
import { menuItems } from "@/constants/menu";
import { useNotificationStore } from "@/store/notificationStore";
import { useSuccessIntelligenceMarkupStore } from "@/store/successIntelligence/successIntelligenceMarkupStore";
import { useUserProfileStore } from "@/store/userProfileStore";

const route = useRoute();
const successIntelligenceMarkupStore = useSuccessIntelligenceMarkupStore();

const mainContentMarginTop = "20px";

const notificationsStore = useNotificationStore();
const notifications = computed(() => notificationsStore.notifications);
const userProfileStore = useUserProfileStore();
const availableMenuItems = computed(() => menuItems.filter((item) => !item.featureName || userProfileStore.hasFeature(item.featureName)));
const routeNamesWithoutVMargin = ["interactions", "interaction-id"];
const isPageContentWithoutVMargin = computed(() => routeNamesWithoutVMargin.includes(route.name ? route.name.toString() : ""));

onMounted(() => {
  nextTick(() => {
    successIntelligenceMarkupStore.mainContentMarginTop = Number(mainContentMarginTop.replace(/[^0-9]/g, ""));
    successIntelligenceMarkupStore.topbarHeight = 124;
  });
});
</script>

<style scoped lang="scss">
.main-container {
  display: flex;
  height: 100vh;
  min-height: var(--page-min-height);
  width: 100%;

  @media (min-width: 1640px) {
    --sidebar-width: var(--sidebar-expanded-width);
  }
}

.aside {
  max-width: var(--sidebar-width);
  width: 100%;
}

.main-content {
  display: flex;
  flex-direction: column;
  width: calc(100% - var(--sidebar-width));
  height: 100vh;
  min-height: var(--page-min-height);

  .top-bar {
    display: flex;
    align-items: center;
    background: var(--surface-color-surface-card-light);
    border-bottom: 1px solid var(--border-color-neutral-subtle-light);
    width: 100%;
    z-index: 20;
  }

  .page-content-wrapper {
    height: 100%;
    overflow: auto;

    .page-content {
      width: 100%;
      max-width: var(--content-width);
      padding: 0 24px;
      margin: v-bind(mainContentMarginTop) auto;

      &.no-v-margin {
        height: 100%;
        margin: 0 auto;
        padding: 0;
      }
    }
  }
}

.notification-wrapper {
  position: fixed;
  bottom: 48px;
  right: 48px;
  z-index: 1000;

  .notification-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.list-enter-active,
.list-leave-active {
  transition: all 0.3s;
  transform: translateX(300px);
}

.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.list-enter-to,
.list-leave {
  opacity: 1;
  transform: translateX(0px);
}
</style>
