<template>
  <div class="sidebar">
    <div>
      <div class="logo">
        <a @click="navigateToManagerPortal">
          <img src="/logo.svg" alt="application logo" class="logo-icon" />
          <span class="logo-name">Spokn AI</span>
        </a>
      </div>
      <nav>
        <ul class="nav-menu">
          <NuxtLink
            v-for="item in menuItems"
            :key="item.name"
            :to="item.path"
            :class="['nav-menu-item']"
            :title="item.name"
            :style="`font-size: ${item.size}`"
          >
            <span :class="[isMenuItemActive(item.path) ? item.activeIcon : item.icon, 'nav-menu-item-icon']"></span>
            <span class="nav-menu-item-text">{{ item.name }}</span>
          </NuxtLink>
        </ul>
      </nav>
    </div>
    <div class="user-menu">
        <sl-dropdown placement="right">
          <div slot="trigger" class="user-menu-button">
            <span class="img-border">
              <img src="/avatar.webp" alt="user avatar" />
            </span>
            <span class="user-menu-button-label">Settings</span>
          </div>
          <div class="dropdown-content">
            <h3 class="user-name">{{ userProfileStore.fullName }}</h3>
            <mc-button
              full-color-hover
              icon="icon-user-tie"
              icon-size="medium"
              text-color="whiteText"
              clear
              @click="navigateToManagerPortal"
              class="navigate-to-portal"
            >Manager Portal</mc-button>
            <div v-if="appVersion" class="app-version">
              Spokn AI Version: {{ appVersion }}
            </div>
          </div>
        </sl-dropdown>
      </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import { useRoute, useRuntimeConfig } from "nuxt/app";
import { RuntimeConfig } from "nuxt/schema";
import McButton from "@/components/ui/McButton.vue";
import { useUserProfileStore } from "@/store/userProfileStore";
import { useUnsavedChangesStore } from "@/store/unsavedChangesStore";
import { IMenuItem } from "@/models/menu.model";

defineProps<{
  menuItems: IMenuItem[];
}>();

const runtimeConfig: RuntimeConfig = useRuntimeConfig();
const userProfileStore = useUserProfileStore();
const unsavedChangesStore = useUnsavedChangesStore();

const appVersion = runtimeConfig.public.appVersion;

const route = useRoute();
const isMenuItemActive = (path: string) => route.path === path;

onMounted(() => {
  import("@shoelace-style/shoelace/dist/components/icon/icon.js");
  import("@shoelace-style/shoelace/dist/components/dropdown/dropdown.js");
});

const navigateToManagerPortal = (): void => {
  const portalLink = userProfileStore.portalLink ?? "/";
  if (unsavedChangesStore.getUnsavedChangesState) {
    unsavedChangesStore.showUnsavedChangesDialog().then((result: boolean) => {
      if (result) return;
      window.location.href = portalLink;
    });
  }
  else {
    window.location.href = portalLink;
  }
};
</script>

<style scoped lang="scss">
.sidebar {
  --nav-menu-item-height: 42px;
  --menu-active-font-weight: 700;
  --menu-item-border-radius: 3px;
  --sidebar-h-padding: 5px;
  --user-menu-height: 42px;
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px var(--sidebar-h-padding);
  min-height: 100%;
  background-color: var(--primary-color);

  @media (min-width: 1640px) {
    --sidebar-width: var(--sidebar-expanded-width);

    .logo {
      &-name {
        display: block;
      }
    }

    .nav-menu {
      &-item {
        width: 100%;
        justify-content: flex-start;
        gap: 8px;

        &.router-link-active {
          padding-left: var(--sidebar-h-padding);
        }
      }
      &-item-text {
        display: block;
      }
    }

    .user-menu {
      margin: 0 8px;
      padding: 0 8px;

      &-button {
        justify-content: flex-start
      }

      &-button-label {
        display: block;
      }

      .dropdown-content {
        margin-left: 30px;
      }
    }
  }
}

.logo {
  margin: 0;
  cursor: pointer;

  a {
    display: flex;
    align-items: center;
  }

  &-icon {
    width: 40px;
    height: 40px;
  }
  &-name {
    font-family: var(--font-family-primary);
    font-size: var(--font-size-md);
    font-weight: 400;
    color: var(--text-color-neutral-dark);
    margin-left: 8px;
    display: none;
  }
}

.nav-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 16px 0;
  padding: 0;

  &-item {
    font-size: var(--font-size-menu-icons);
    width: 100%;
    height: var(--nav-menu-item-height);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    text-decoration: none;
    border-radius: var(--menu-item-border-radius);

    &.router-link-active {
      padding-left: calc(var(--sidebar-h-padding) * 2);
      margin-left: calc(var(--sidebar-h-padding) * -2);

      .nav-menu-item-icon {
        font-weight: var(--menu-active-font-weight);
      }

      .nav-menu-item-text {
        font-weight: var(--menu-active-font-weight);
      }
    }
    &.router-link-active::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 6px;
      height: var(--nav-menu-item-height);
      background-color: var(--spokn-brand-color);
      border-radius: 0px 4px 4px 0px;
    }

    &:hover:not(.router-link-active) {
      background: var(--background-color-neutral-hover-a-dark);
    }

    &-text {
      font-size: var(--font-size-sm);;
      font-weight: 500;
      color: var(--text-color-neutral-dark);
      display: none;
    }

    &-icon {
      color: var(--text-color-neutral-dark);
      width: var(--nav-menu-item-height);
      height: var(--nav-menu-item-height);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.user-menu {
  display: flex;
  align-items: center;
  height: var(--user-menu-height);
  border-radius: var(--menu-item-border-radius);
  cursor: pointer;

  &-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    color: var(--text-color-neutral-dark);
    height: var(--user-menu-height);

    &-label {
      display: none;
    }
  }

  &:hover {
    background: var(--background-color-neutral-hover-a-dark);
  }

  .img-border {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    background: var(--mauve-light-7);
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img {
      border-radius: 100%;
      object-fit: cover;
      display: block;
      cursor: pointer;
    }
  }

  .dropdown-content {
    min-width: 193px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: var(--primary-color);
    padding: 12px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    margin-bottom: 10px;
    margin-left: 15px;
    cursor: text;
  }

  .user-name {
    font-family: var(--font-family-primary);
    font-size: var(--font-size-xl);
    font-weight: 400;
    color: var(--text-color-neutral-dark);
    margin: 0;
    margin-left: var(--spacing-sm);
  }

  .navigate-to-portal {
    width: 100%;
    --button-main-color: var(--spokn-brand-color);
    justify-content: unset;
  }

  sl-dropdown {
    width: 100%;
  }
  sl-dropdown[open] {
    .img-border {
      background: var(--green-light-verdigris);
    }
  }

  .app-version {
    font-size: var(--font-size-xs);
    font-weight: 500;
    color: var( --text-color-neutral-subtle-light);
    padding-left: 8px;
  }
}
</style>
