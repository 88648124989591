<template>
  <dialog ref="dialog" :class="[dialogClass]">
    <div class="dialog-content">
      <div class="dialog-header">
        <slot name="header">
          <span class="title">{{ title }}</span>
        </slot>
      </div>
      <div class="dialog-body">
        <slot></slot>
      </div>
      <div class="dialog-footer">
        <slot name="actions">
          <div v-if="showActionButtons" class="actions">
            <McButton class="dialog-button" :class="[cancelButtonClass]" @click="handleCancelButtonClick">
              {{cancelButtonText}}
            </McButton>
            <McButton class="dialog-button" :class="[submitButtonClass]" @click="handleSubmitButtonClick">
              {{submitButtonText}}
            </McButton>
          </div>
        </slot>
      </div>
    </div>
  </dialog>
</template>

<script lang="ts" setup >
import { ref, toRefs } from 'vue';
import McButton from '@/components/ui/McButton.vue';

interface IMcDialogProps {
  title?: string;
  dialogClass?: string;
  showActionButtons?: boolean;
  submitButtonText?: string;
  submitButtonClass?: string;
  cancelButtonText?: string;
  cancelButtonClass?: string;
};

const props = withDefaults(defineProps<IMcDialogProps>(), {
  title: "",
  dialogClass: "",
  showActionButtons: true,
  submitButtonText: "Save",
  submitButtonClass: "",
  cancelButtonText: "Close",
  cancelButtonClass: "",
});

const {
  title, 
  dialogClass,
  submitButtonText, 
  submitButtonClass,
  cancelButtonText,
  cancelButtonClass } = toRefs(props);

const dialog = ref<HTMLDialogElement | null>(null)

const emit = defineEmits(['submit', 'cancel']);

const handleSubmitButtonClick = () => {
    closeDialog();
    emit('submit');
};

const handleCancelButtonClick = () => {
    closeDialog();
    emit('cancel');
};

function openDialog() {
    dialog.value?.showModal();
};

function closeDialog() {
    dialog.value?.close();
}

defineExpose({
    openDialog,
    closeDialog
});
</script>

<style scoped lang="scss">
dialog {
  --dialog-width: 475px;
  --dialog-height: fit-content;
  --dialog-border-radius: 8px;

  width: var(--dialog-width);
  height: var(--dialog-height);
  max-height: 85vh;
  background: var(--surface-color-surface-overlay-light);
  box-shadow: 0px 2px 14px 0px rgba(3, 20, 71, 0.30);
  border-radius: var(--dialog-border-radius);
  border: none;
  padding: 36px 32px 24px 32px;

  &::backdrop {
      background: rgba(0, 0, 0, 0.50);;
  }
}

.dialog-content {
  display: flex;
  flex-direction: column;
  border-radius: var(--dialog-border-radius);
  height: 100%;
}

.dialog-header {
  .title {
    color: var(--text-color-neutral-light);
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 100% */
  }
}

.dialog-body {
  flex: 1;
  overflow-y: auto;
}

.dialog-footer {
  .actions {
    gap: 6px;
    display: flex;
    margin-top: 16px;
    justify-content: flex-end;

    .dialog-button {
      --button-padding: 11px 20px;
    }
  }
}
</style>